// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-code-projects-js": () => import("./../../../src/pages/code-projects.js" /* webpackChunkName: "component---src-pages-code-projects-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-client-js": () => import("./../../../src/pages/projects/client.js" /* webpackChunkName: "component---src-pages-projects-client-js" */),
  "component---src-pages-projects-conversational-design-js": () => import("./../../../src/pages/projects/conversational-design.js" /* webpackChunkName: "component---src-pages-projects-conversational-design-js" */),
  "component---src-pages-projects-design-system-js": () => import("./../../../src/pages/projects/design-system.js" /* webpackChunkName: "component---src-pages-projects-design-system-js" */),
  "component---src-pages-projects-mercado-js": () => import("./../../../src/pages/projects/mercado.js" /* webpackChunkName: "component---src-pages-projects-mercado-js" */),
  "component---src-pages-projects-yolko-js": () => import("./../../../src/pages/projects/yolko.js" /* webpackChunkName: "component---src-pages-projects-yolko-js" */)
}

